import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { useState } from 'react'
import ValidateButton from '../buttons/ValidateButton'
import { useMeQuery } from '@gqlTypes'

export default function SelectorUpdater({ onValidate, items, children = null, field, title }) {
	const [value, setValue] = useState('')
	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })
	if (loading || data?.me == null) return null

	const handleSelect = (event) => {
		setValue(event.target.value)
	}

	const isDisabled = items.length < 2 && (data.me.isRestrictedAccess ?? false)

	return (
		<DialogSection>
			<label htmlFor="massUpdate-status">{title}</label>
			<Select
				id="massUpdate-status"
				value={value}
				onChange={handleSelect}
				className="main-dialog-element"
				disabled={isDisabled}
			>
				{children && children}
				{!children &&
					items.map((item) => (
						<MenuItem key={item.value} value={item.value}>
							{item.label}
						</MenuItem>
					))}
			</Select>
			<ValidateButton onValidate={onValidate} value={value} field={field} disabled={isDisabled} />
		</DialogSection>
	)
}
