import ArchivedUpdateSection from '@features/modals/components/sections/booleanUpdaters/ArchivedUpdateSection'
import Divider from '@mui/material/Divider'
import { type ReactElement } from 'react'
import { type PositionStatus, useGetManagersQuery, useMeQuery } from '@gqlTypes'
import SelectorUpdater from '@features/modals/components/sections/SelectorUpdater'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	sendUpdate: (field: string, newValue: string | boolean) => Promise<void>
}

export const StudentMassUpdateModal = ({ sendUpdate }: Props): ReactElement | null => {
	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })
	const { data: dataManagers } = useGetManagersQuery()
	const managers = dataManagers?.getManagers ?? []
	const { t } = useCustomTranslation()
	if (loading || data?.me == null) return null

	const organization = data.me.organization
	const availablePromotions = organization.promotions.map((value: string) => ({ value, label: value }))
	const availableBusinessUnits = organization?.businessUnits.map((value: string) => ({ value, label: value }))
	const availableLocations = organization.locations.map((value: string) => ({ value, label: value }))
	const availableStatus = organization.positionStatus.map((status: PositionStatus) => ({
		value: status.id,
		label: status.label
	}))
	const availableManagers = managers.map(({ id, fullName }) => ({ value: id, label: fullName }))
	return (
		<>
			<SelectorUpdater
				onValidate={sendUpdate}
				items={availableBusinessUnits}
				field="businessUnit"
				title={t('students:columns.businessUnit')}
			/>
			<Divider />
			<SelectorUpdater
				onValidate={sendUpdate}
				items={availablePromotions}
				field="promotion"
				title={t('students:columns.promotion')}
			/>
			<Divider />
			<SelectorUpdater
				onValidate={sendUpdate}
				items={availableLocations}
				field="location"
				title={t('students:columns.campus')}
			/>
			<Divider />
			<SelectorUpdater
				onValidate={sendUpdate}
				items={availableManagers}
				field="manager"
				title={t('students:columns.manager')}
			/>
			<Divider />
			<SelectorUpdater
				onValidate={sendUpdate}
				items={availableManagers}
				field="externalAssessorId"
				title={t('students:columns.tutor')}
			/>
			<Divider />
			<SelectorUpdater
				onValidate={sendUpdate}
				items={availableStatus}
				field="status"
				title={t('students:columns.status')}
			/>
			<Divider />
			<ArchivedUpdateSection onValidate={sendUpdate} />
			<Divider />
		</>
	)
}
