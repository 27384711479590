import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { type ReactElement, type ReactNode } from 'react'

interface Props {
	icon: ReactNode
	route: string
	tooltipTitle: string
}

export const RoutingButtonIcon = ({ route, icon, tooltipTitle }: Props): ReactElement => {
	return (
		<Tooltip title={tooltipTitle}>
			<IconButton aria-label={tooltipTitle} style={{ cursor: 'pointer' }} href={route} target="_blank">
				{icon}
			</IconButton>
		</Tooltip>
	)
}
