import Typography from '@mui/material/Typography'
import { type ReactElement } from 'react'

interface Props {
	firstname: string
	lastname: string
	phoneNumber: string
}

export const CandidateInfoCell = ({ firstname, lastname, phoneNumber }: Props): ReactElement => {
	return (
		<div>
			<Typography>
				<strong>{lastname}</strong> {firstname}
			</Typography>
			<Typography variant="body2">{phoneNumber}</Typography>
		</div>
	)
}
