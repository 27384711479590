import { useTranslation } from 'react-i18next'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { InfoCardToolbar } from '@ui/core/InfoCardToolbar'
import Button from '@mui/material/Button'
import { PersonalityProfileCard } from '@shared/components/cards/PersonalityProfileCard'
import { CommunicationKeyCard } from '@shared/components/cards/CommunicationKeyCard'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import DimensionSlider from './DimensionSlider'
import { INTEGRATION_TYPE } from '../../constants/INTEGRATION_TYPE'
import { KeyIndicatorsBar } from './KeyIndicatorBar/KeyIndicatorsBar'
import { SoftskillsIndicatorsCard } from './SoftskillsIndicatorsCard/SoftskillsIndicatorsCard'
import { generateRoute } from '../../router/routes'
import { history } from '@shared/store/MyStoreProvider'
import { Grid, Stack, Typography } from '@mui/material'
import { MotivationVideoCard } from '@domains/motivations/MotivationVideoCard'
import { MotivationQuestions } from '@domains/motivations/question/MotivationQuestions'
import { useGetProfileQuery } from '@gqlTypes'
import useSoftskillsIndicators from './useSoftskillsIndicators'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

export default function Profile({ integrationType, id, trackType, discussionRoute }) {
	const isCandidacy = integrationType === INTEGRATION_TYPE.CANDIDACY
	const { data, loading: isLoading } = useGetProfileQuery({
		variables: { id, trackType },
		fetchPolicy: 'cache-and-network'
	})

	const { t } = useTranslation(['translation', 'dimension', 'profileProfiling', 'profile'])
	useDocumentTitle(t('translation:candidates.profile.title', { name: data?.getUserIntegration?.user?.fullName ?? '' }))

	const { isSoftSkillLoading, aboveIndicators, belowIndicators } = useSoftskillsIndicators(data?.getUserIntegration)

	if (isLoading || data == null) return <LocalLoader />

	const paramName = isCandidacy ? 'userOfferId' : 'userPositionId'

	const motivationTest = data?.getMotivationTestByUserIntegration
	const motivationQuestionGroups = motivationTest?.motivationQuestionGroups ?? []
	const questions = motivationQuestionGroups.map((group) => group.questions).flat()
	const videoQuestions = questions.filter((question) => question.isAnswerVideo)

	const { alignment, user, sharing, interview } = data.getUserIntegration
	const personalityTest = user.personalityTest[0]
	const dimensions = personalityTest?.dimensions ?? []

	const personalityDimension = (
		<CardLayout title={t('translation:candidates.profile.personalityDimensions.title')}>
			<InfoCardContent>
				{dimensions.map((dimension) => (
					<DimensionSlider
						key={dimension.id}
						min={-0.5}
						max={0.5}
						score={dimension.score - 0.5}
						startLabel={dimension.lowScoreName}
						endLabel={dimension.highScoreName}
						description={dimension.description}
					/>
				))}
				{dimensions.length === 0 && <Typography>{t('translation:optionalValue.defaultLabel')}</Typography>}
			</InfoCardContent>
		</CardLayout>
	)

	const unsharedProfile = (
		<CardLayout>
			<InfoCardContent>{t('translation:candidates.profile.unshared.description')}</InfoCardContent>
			<InfoCardToolbar>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => history.push(generateRoute(discussionRoute, { [paramName]: id }))}
				>
					{t('translation:candidates.profile.unshared.sendMessageLabel')}
				</Button>
			</InfoCardToolbar>
		</CardLayout>
	)

	return (
		<Grid container spacing={3} mb={3}>
			<Grid item xs={6}>
				<Stack spacing={3}>
					{sharing?.sharedProfile && (
						<>
							<PersonalityProfileCard
								avatar={personalityTest?.avatar}
								profile={personalityTest?.profile}
								highestDimensions={personalityTest?.highestDimensions}
							/>
							{personalityDimension}
							<CommunicationKeyCard profilingId={user.profilingId} profileId={personalityTest?.profile?.id} />
						</>
					)}
					<MotivationVideoCard questions={videoQuestions} />
					{!sharing?.sharedProfile && <>{unsharedProfile}</>}
				</Stack>
			</Grid>
			<Grid item xs={6}>
				<Stack spacing={3}>
					<SoftskillsIndicatorsCard
						isLoading={isSoftSkillLoading}
						aboveIndicators={aboveIndicators}
						belowIndicators={belowIndicators}
						alignment={alignment}
						sharedSoftSkillsTest={sharing?.sharedSoftSkillsTest}
						sharedSoftSkillsReturn={sharing?.sharedSoftSkillsReturn}
						interviewScore={interview?.score}
						title={t('translation:candidates.profile.softSkills.title')}
					/>
					{motivationQuestionGroups.map(({ id, label, questions }) => (
						<MotivationQuestions key={id} questions={questions} title={label} />
					))}
				</Stack>
			</Grid>
			<Grid item xs={12}>
				<KeyIndicatorsBar userId={user.id} title={t('translation:candidates.profile.mainIndicators.title')} hasLegend />
			</Grid>
		</Grid>
	)
}
