import { InfoCardContent } from '@ui/core/InfoCardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'
import { type GetProfileQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { useTheme } from '@mui/material'

type PersonalityTest = NonNullable<
	NonNullable<NonNullable<GetProfileQuery['getUserIntegration']>['user']['personalityTest']>[0]
>

interface Props {
	avatar: PersonalityTest['avatar']
	profile: PersonalityTest['profile']
	highestDimensions: PersonalityTest['highestDimensions']
}

export const PersonalityProfileCard = ({ avatar, profile, highestDimensions }: Props): ReactElement | null => {
	const theme = useTheme()
	const { t } = useTranslation(['translation', 'dimension', 'profile'])

	if (profile == null) return null

	return (
		<CardLayout title={t('translation:candidates.profile.personalityProfile.title')}>
			<InfoCardContent style={{ display: 'flex' }}>
				{avatar.url !== '' && (
					<div className="avatarPanel">
						<Avatar
							style={{
								width: 150,
								height: 150,
								marginBottom: 25,
								marginRight: 50,
								backgroundColor: theme.palette.secondary.main,
								fontSize: 50
							}}
							src={avatar.url ?? ''}
						/>
						<Typography>{avatar.name}</Typography>
						<Typography variant="body2">{avatar.description}</Typography>
					</div>
				)}
				<div style={{ flex: 1, gap: 10 }}>
					<Typography variant="h6" sx={{ color: 'lightBlue.main' }}>
						{t('translation:candidates.profile.personalityProfile.profileLabel', { name: profile.name })}
					</Typography>
					<Typography>
						<strong>
							{t('translation:candidates.profile.personalityProfile.traits', { traits: highestDimensions })}
						</strong>
					</Typography>
					<Typography>{profile.description}</Typography>
				</div>
			</InfoCardContent>
		</CardLayout>
	)
}
