import { Marker } from './Marker'
import { useTranslation } from 'react-i18next'
import StarRate from '@mui/icons-material/StarRate'
import theme from '@ui/theme'
import { type ReactElement } from 'react'

interface Props {
	value: number
	imageResponseDetailedLabel: string
}

export const ImageResponseMarker = ({ value, imageResponseDetailedLabel }: Props): ReactElement => {
	const { t } = useTranslation()
	const title = t('translation:candidates.profile.interviewEvaluation')

	const label =
		imageResponseDetailedLabel != null && imageResponseDetailedLabel !== ''
			? `${title} :\n${imageResponseDetailedLabel}`
			: title

	return (
		<Marker
			label={label}
			value={value}
			icon={<StarRate fontSize="inherit" color="secondary" />}
			arrowColor={theme.palette.secondary.main}
		/>
	)
}
