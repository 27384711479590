import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import { type StyleHTMLAttributes, type ReactElement, type ReactNode } from 'react'

interface Props {
	children?: ReactNode
	color?: 'default' | 'primary'
	dataTestId?: string
	className?: string
	style?: StyleHTMLAttributes<HTMLDivElement>['style']
}

const useStyle = makeStyles()((theme) => ({
	root: {
		paddingLeft: 38,
		paddingRight: 38,
		paddingTop: 34,
		paddingBottom: 34,
		flex: 1
	},
	defaultColor: {},
	primaryColor: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText
	}
}))

export const InfoCardContent = ({ children, ...props }: Props): ReactElement => {
	const { classes } = useStyle()

	const { color = 'default', dataTestId = '', className = {} } = props

	return (
		<div
			{...props}
			className={clsx(classes.root, 'emageme-infocardcontent', className, classes[`${color}Color`])}
			data-testid={dataTestId}
		>
			{children}
		</div>
	)
}
