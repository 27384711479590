import { type ReactElement, useState } from 'react'
import { type UserPositionListNodeFragment } from '@gqlTypes'
import { CurrentExternalAssessor } from './CurrentExternalAssessor'
import { StudentCardEditDialog } from './edit/StudentCardEditDialog'
import { PositionCard } from '@features/lists/positions/PositionCard'
import { useTranslation } from 'react-i18next'
import { ROUTE_STUDENTS_DETAIL } from '../../../router/routes'
import { useRole } from '@shared/hooks/useRole'

interface Props {
	position: UserPositionListNodeFragment
	isSelected?: boolean
	selectRow?: () => void
}

export const StudentCard = ({ position, isSelected, selectRow }: Props): ReactElement => {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useTranslation()
	const { screens } = useRole()

	return (
		<PositionCard
			position={position}
			isSelected={isSelected}
			selectRow={selectRow}
			dialogComponent={
				<StudentCardEditDialog
					position={position}
					handleClose={() => {
						setIsOpen(false)
					}}
					isOpen={isOpen}
				/>
			}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			linkLabel={t('students:columns.link')}
			detailRoute={ROUTE_STUDENTS_DETAIL}
			riskTitle={t('risk:modal.title.student')}
			isEditable={screens.students.hasWriteAccess}
		>
			<CurrentExternalAssessor
				assessorFullName={position?.externalAssessor?.fullName ?? ''}
				isValid={position?.isExternalAssessorValidated ?? false}
			/>
		</PositionCard>
	)
}
