import { IconButton, Tooltip, Typography, Box } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import { makeStyles } from 'tss-react/mui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ConditionalWrapper } from '@shared/components/ConditionalWrapper'
import { type MouseEventHandler } from 'react'

interface Props {
	selected: boolean
	label: string
	description?: string
	onRemove?: MouseEventHandler<HTMLButtonElement>
	onAdd?: () => void
	children?: React.ReactNode
	id: string
	isDraggable?: boolean
}

export default function MultiSelectItem({
	selected,
	label,
	description,
	onRemove,
	onAdd,
	children,
	id,
	isDraggable = true
}: Props): JSX.Element {
	const { classes } = useStyles()
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

	return (
		<ConditionalWrapper
			condition={isDraggable}
			wrapper={(children) => (
				<Box
					ref={setNodeRef}
					{...attributes}
					{...listeners}
					sx={{
						transform: CSS.Transform.toString(transform),
						transition,
						cursor: 'grab',
						touchAction: 'none'
					}}
				>
					{children}
				</Box>
			)}
		>
			<div className={classes.row}>
				<div>
					{selected ? (
						<IconButton>
							<MenuIcon />
						</IconButton>
					) : (
						<IconButton
							onClick={() => {
								if (onAdd != null) onAdd()
							}}
						>
							<AddBoxOutlinedIcon />
						</IconButton>
					)}
				</div>
				<Typography className={classes.label}>{label}</Typography>
				{children}
				<div className={classes.icons}>
					{selected ? (
						<IconButton data-no-dnd="true" onClick={onRemove}>
							<DeleteIcon />
						</IconButton>
					) : null}
					{description !== '' && description != null ? (
						<Tooltip title={description}>
							<IconButton>
								<InfoOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
				</div>
			</div>
		</ConditionalWrapper>
	)
}

const useStyles = makeStyles()(() => ({
	row: {
		display: 'flex'
	},
	label: {
		flexGrow: 1,
		marginTop: '8px'
	},
	icons: {
		display: 'flex',
		alignItems: 'start'
	}
}))
