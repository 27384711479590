import { useEffect, type ReactElement } from 'react'
import { type PositionStatus, type UserPositionListNodeFragment, useMeQuery } from '@gqlTypes'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { usePositionUpdate } from '../usePositionUpdate'
import { IntegrationAvatar } from '@shared/components/IntegrationAvatar'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'
import { FormProvider, useForm } from 'react-hook-form'
import { AutocompleteSelect } from '@domains/form/AutocompleteSelect'
import { ManagerSelect } from './ManagerSelect'
import { TutorSelect } from './TutorSelect'
import { TutorValidator } from './TutorValidator'
import LoadingButton from '@mui/lab/LoadingButton'
import { useCustomTranslation } from '#translations/useCustomTranslation'

interface Props {
	position: UserPositionListNodeFragment
	handleClose: () => void
	isOpen: boolean
}
interface FormInputs {
	businessUnit: string
	location: string
	manager: string
	externalAssessorId: string
	isExternalAssessorValidated: boolean
	status: string
}

export const StudentCardEditDialog = ({ position, handleClose, isOpen }: Props): ReactElement | null => {
	const { t } = useCustomTranslation()
	const { update } = usePositionUpdate(position)
	const defaultValues = {
		businessUnit: position.businessUnit ?? '',
		promotion: position.promotion ?? '',
		location: position.location ?? '',
		manager: position.manager ?? '',
		externalAssessorId: position.externalAssessorId ?? '',
		isExternalAssessorValidated: position.isExternalAssessorValidated ?? '',
		status: position.status ?? ''
	}
	const formMethods = useForm<FormInputs>({ mode: 'onChange', defaultValues })
	const { handleSubmit, reset } = formMethods
	useEffect(() => {
		reset(defaultValues)
	}, [position])

	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })

	if (loading || data?.me == null) return null
	const organization = data.me.organization
	const availablePromotions = organization.promotions.map((promotion: string) => ({
		value: promotion,
		label: promotion
	}))
	const availableLocations = organization.locations.map((loc: string) => ({ value: loc, label: loc }))
	const availableBusinessUnits = organization?.businessUnits.map((bu: string) => ({ value: bu, label: bu }))
	const availableStatus = organization.positionStatus.map((status: PositionStatus) => ({
		value: status.id,
		label: status.label
	}))

	const isBusinessUnitRestricted = (data.me.businessUnit ?? '') !== ''
	const isLocationRestricted = (data.me.location ?? '') !== ''

	const updateUserPosition = (values: FormInputs): void => {
		update(values)
		handleClose()
	}

	const userRef = position.userInfo
	if (userRef == null || !isOpen) return null

	return (
		<Dialog onClose={handleClose} open={isOpen}>
			<FormProvider {...formMethods}>
				<DialogCloseButton onClick={handleClose} />
				<DialogTitle color="primary" sx={{ mb: 3, fontWeight: 'bold' }}>
					{t('students:update.title')}
				</DialogTitle>
				<DialogContent>
					<IntegrationAvatar
						sx={{ mb: 5 }}
						userRef={userRef}
						title={position.orgaPosition?.label ?? ''}
						subtitle={position?.userInfo?.currentCompany ?? ''}
					/>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6}>
							<AutocompleteSelect
								name="businessUnit"
								items={availableBusinessUnits}
								label={t('students:columns.businessUnit')}
								noneValueLabel={t('students:cell.businessUnit.none')}
								isRestricted={isBusinessUnitRestricted}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<AutocompleteSelect
								name="promotion"
								items={availablePromotions}
								label={t('students:columns.promotion')}
								noneValueLabel={t('students:cell.promotion.none')}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<AutocompleteSelect
								name="location"
								items={availableLocations}
								label={t('students:columns.campus')}
								noneValueLabel={t('students:cell.location.none')}
								isRestricted={isLocationRestricted}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<ManagerSelect />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TutorSelect />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TutorValidator />
						</Grid>
						<Grid item xs={12} sm={6}>
							<AutocompleteSelect
								name="status"
								items={availableStatus}
								label={t('students:columns.status')}
								noneValueLabel=""
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t('students:update.cancel')}</Button>
					<LoadingButton onClick={handleSubmit(updateUserPosition)} color="secondary" variant="contained">
						{t('students:update.edit')}
					</LoadingButton>
				</DialogActions>
			</FormProvider>
		</Dialog>
	)
}
