import { type ReactElement, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import { FormProvider, useForm } from 'react-hook-form'
import { AssessorField } from './AssessorField'
import { DateField } from './DateField'
import { StartTimeField } from './StartTimeField'
import { LabelField } from './LabelField'
import { useSelector } from 'react-redux'
import { AssessedField } from './AssessedField'
import { Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import useRoleContext from '@shared/hooks/useRoleContext'
import useUserBContext from '@shared/hooks/useUserBContext'
import { type CreateInterviewMutationVariables, useCreateInterviewMutation, useMeQuery } from '@gqlTypes'
import { InterviewCustomFields } from './InterviewCustomFields'
import { makeStyles } from 'tss-react/mui'
import { InterviewTypeField } from './InterviewTypeField'
import { useSnackbar } from 'notistack'
import { Severities } from '../../../constants/Severities'
import { ErrorHandler } from '#helpers/error.helper'
import AddIcon from '@mui/icons-material/Add'
import { IconAction } from '@shared/icon/IconAction'
import { DurationField } from './DurationField'
import { LoadingButton } from '@mui/lab'

interface Props {
	reset: () => void
}

interface FormInputs {
	targetDate: Date
	startTime?: Date
	userIntegration: { value: { id: string; type: string } }
	label: string
	juryId: string
	interviewTypeId?: string
	durationId?: string
	customFields: Record<string, string>
}

export const NewInterviewDrawer: React.FC<Props> = ({ reset }: Props): ReactElement => {
	const { classes } = useStyle()
	const defaultValues: FormInputs = {
		juryId: '',
		userIntegration: undefined,
		interviewTypeId: '',
		targetDate: new Date(),
		durationId: '60',
		label: '',
		customFields: {}
	}
	const formMethods = useForm<FormInputs>({ mode: 'onChange', defaultValues })
	const { handleSubmit, errors } = formMethods
	const { t } = useTranslation()
	const role = useRoleContext()
	const userB = useUserBContext()
	const { enqueueSnackbar } = useSnackbar()
	const { data, loading: isMeLoading } = useMeQuery()
	const [isOpen, setIsOpen] = useState(false)
	const isRestrictedAccess: boolean = useSelector(
		({ userB }: { userB: { isRestrictedAccess: boolean } }) => userB.isRestrictedAccess
	)
	const isAssessor = role.roleType === 'assessor'
	const [createInterview, { loading }] = useCreateInterviewMutation()
	const isDisplayAssessor = !isAssessor && !isRestrictedAccess

	const onSubmit = (data: FormInputs): void => {
		;(async () => {
			const { targetDate, label, juryId, interviewTypeId, durationId, startTime, userIntegration, customFields } = data

			const actualJuryId: string = isAssessor || isRestrictedAccess ? userB.id : juryId

			const variables: CreateInterviewMutationVariables = {
				juryId: actualJuryId,
				userIntegrationId: userIntegration.value.id,
				integrationType: userIntegration.value.type,
				interview: {
					targetDate,
					customFields: customFields ?? {},
					label: label ?? '',
					interviewTypeId: interviewTypeId ?? '',
					durationId,
					startTime
				}
			}

			const onCompleted = (): void => {
				setIsOpen(false)
				reset()
			}
			const result = await createInterview({ variables, onCompleted })
			if (result.errors != null) enqueueSnackbar(t('drawer:interview.snackBar.error'), { variant: Severities.error })
		})().catch(ErrorHandler)
	}

	if (isMeLoading || data?.me == null) return <LocalLoader />
	const isInterviewLabelAvailable = data.me.organization.isInterviewLabelAvailable

	return (
		<>
			<IconAction
				tooltip={t('drawer:interview.title')}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<AddIcon />
			</IconAction>
			<Drawer
				anchor="right"
				open={isOpen}
				onClose={() => {
					setIsOpen(false)
				}}
			>
				<form className={classes.form}>
					<FormProvider {...formMethods}>
						<Typography color="primary" sx={{ fontWeight: 'bold', p: 2 }}>
							{t('drawer:interview.title')}
						</Typography>
						<Divider />
						<Stack spacing={3} direction="column" sx={{ width: 350, padding: 2 }}>
							{isDisplayAssessor ? <AssessorField /> : null}
							<AssessedField />
							<InterviewTypeField />
							{isInterviewLabelAvailable ? <LabelField /> : null}
							<DateField name="targetDate" label={t('drawer:interview.date')} />
							<InterviewCustomFields errors={errors} />
							<Divider />
							<Typography color="primary" sx={{ fontWeight: 'bold' }}>
								{t('drawer:interview.invitation')}
							</Typography>
							<StartTimeField />
							<DurationField />
							<LoadingButton loading={loading} variant="contained" onClick={handleSubmit(onSubmit)}>
								{t('drawer:interview.validate')}
							</LoadingButton>
						</Stack>
					</FormProvider>
				</form>
			</Drawer>
		</>
	)
}

const useStyle = makeStyles()((theme) => ({
	bar: {
		display: 'flex',
		backgroundColor: theme.palette.darkBlue.light,
		justifyContent: 'center',
		alignItems: 'center',
		height: 40
	},
	text: {
		fontWeight: 'bold',
		color: 'white'
	},
	form: {
		height: '100%'
	}
}))
