import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'

export default function ValidateButton({ value, field, onValidate }) {
	const { t } = useTranslation(['lists'])

	return (
		<Button disabled={value === ''} onClick={() => onValidate(field, value)}>
			{value === '' ? t('modal:massUpdate.update') : t('modal:massUpdate.update')}
		</Button>
	)
}
