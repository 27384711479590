export const markerSizes = {
	medium: {
		marker: {
			height: 28,
			width: 17
		},
		markerIcon: {
			width: 15,
			height: 15,
			fontSize: 15,
			top: 1,
			left: 1
		}
	},
	large: {
		marker: {
			height: 34,
			width: 23
		},
		markerIcon: {
			width: 21,
			height: 21,
			fontSize: 18,
			top: 2,
			left: 2
		}
	}
}
