import { type ReactNode, type ReactElement } from 'react'

interface Props {
	condition: boolean
	wrapper: (children: ReactNode) => ReactElement
	children: ReactNode
}

export const ConditionalWrapper = ({ condition, wrapper, children }: Props): ReactElement => {
	return condition ? wrapper(children) : <>{children}</>
}
