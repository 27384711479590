import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Grid, Stack, Typography } from '@mui/material'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { BrandIndicatorsCard } from './BrandIndicatorsCard'
import { GraphRadarCard } from '@shared/components/GraphRadar/components/cards/GraphRadarCard'
import { TrackType, useGetOrganizationFeedbackQuery } from '@gqlTypes'
import { OrganizationReturnsFilterBar } from '../components/OrgaReturnsFilterBar'
import { CommentsCard } from '../components/CommentsCard'
import { useFilters } from '@shared/hooks/useFilters'
import { useRole } from '@shared/hooks/useRole'
import moment from 'moment'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { useOrganization } from '@domains/organization/useOrganization'

export const BrandFeedback = (): ReactElement | null => {
	const { t } = useTranslation()
	const { organization, hasOrgaSkills } = useOrganization()
	useDocumentTitle(t('orgaReturns:title'))
	const { hasOfferAccess, hasPositionAccess } = useRole()

	const value = !hasOfferAccess && hasPositionAccess ? TrackType.Position : TrackType.Offer
	const defaultFilters = [
		{ key: 'trackType', operation: '==', value },
		{ key: 'creationDate', operation: '>=', value: moment().subtract(6, 'months').format('YYYY-MM-DD') },
		{ key: 'creationDate', operation: '<=', value: moment().add(1, 'days').format('YYYY-MM-DD') }
	]
	useFilters()
	const {
		data,
		loading: isLoading,
		refetch
	} = useGetOrganizationFeedbackQuery({ variables: { filters: defaultFilters }, notifyOnNetworkStatusChange: true })
	useDefaultFilter({ manualDefaultFilters: defaultFilters, refetch })
	const { graphDimensions, indicators, requestCount, responseCount, comments, commentsBySkills } =
		data?.getOrganizationFeedback ?? {
			graphDimensions: [],
			indicators: [],
			requestCount: 0,
			responseCount: 0,
			comments: [],
			commentsBySkills: []
		}

	if (organization == null) return null

	return (
		<Container maxWidth="xl">
			<Stack my={3} direction="row" justifyContent="space-between">
				<Stack direction="row" gap={3} alignItems="baseline">
					<Typography color="primary" variant="h5">
						<b>{t('organizationFeedback:title')}</b>
					</Typography>
					<Typography color="primary">
						<b>{t('organizationFeedback:requestCount', { count: requestCount })}</b>
					</Typography>
					<Typography color="primary">
						<b>{t('organizationFeedback:responseCount', { count: responseCount })}</b>
					</Typography>
				</Stack>
				<OrganizationReturnsFilterBar
					organization={organization}
					refetch={refetch}
					requestCount={requestCount}
					defaultFilters={defaultFilters}
				/>
			</Stack>
			<LocalLoader load={isLoading}>
				{graphDimensions.length > 0 ? (
					<Grid container spacing={3} mb={3}>
						<Grid item sm={12} md={6}>
							<Stack spacing={3}>
								<GraphRadarCard dimensions={graphDimensions} />
								<CommentsCard comments={comments} commentsBySkills={commentsBySkills} />
							</Stack>
						</Grid>
						<Grid item sm={12} md={6}>
							<BrandIndicatorsCard
								isLoading={isLoading}
								indicators={indicators}
								isUnavailable={!hasOrgaSkills}
								title={t('translation:candidates.organizationReturn.organizationSKill.title')}
							/>
						</Grid>
					</Grid>
				) : (
					<Typography style={{ textAlign: 'center', marginTop: 60 }}>{t('organizationFeedback:empty')}</Typography>
				)}
			</LocalLoader>
		</Container>
	)
}
