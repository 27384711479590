import { Marker } from './Marker'
import { useTranslation } from 'react-i18next'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import theme from '@ui/theme'
import { type ReactElement } from 'react'

interface Props {
	value: number
}

export const EcosystemMarker = ({ value }: Props): ReactElement => {
	const { t } = useTranslation()

	return (
		<Marker
			label={t('translation:candidates.profile.ecosystemReturn')}
			value={value}
			icon={<SupervisorAccountOutlinedIcon fontSize="inherit" color="secondary" />}
			arrowColor={theme.palette.secondary.main}
			size="large"
		/>
	)
}
