import { type ReactNode, useState, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import FilterBarGQL from './FilterBarGQL'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useFilters } from '@shared/hooks/useFilters'
import { Badge, Button, Divider, Drawer, Stack, Typography } from '@mui/material'
import { IconAction } from '@shared/icon/IconAction'
import { type FilterInput } from '@gqlTypes'

interface Props {
	refetch: (variables: { filters: FilterInput[] }) => void
	children: ReactNode
	defaultFilters?: FilterInput[]
}

export const Filters = ({ refetch, children, defaultFilters }: Props): ReactElement => {
	const { t } = useTranslation(['lists', 'candidates'])
	const { reset, filtersCount, filters } = useFilters()
	const [isOpen, setIsOpen] = useState(false)
	const resetFilters = async (): Promise<void> => {
		await reset(refetch, defaultFilters)
	}

	return (
		<>
			<IconAction
				tooltip={t('modal:filters.tooltip')}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<Badge badgeContent={filtersCount} color="secondary">
					<FilterAltIcon htmlColor="#0A2E56" />
				</Badge>
			</IconAction>
			<Drawer
				anchor="right"
				open={isOpen}
				onClose={() => {
					refetch({ filters })
					setIsOpen(false)
				}}
			>
				<FilterBarGQL refetch={refetch}>
					<Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', gap: 2, m: 3 }}>
						<Typography>
							<b>{t('modal:filters.title')}</b>
						</Typography>
						<Stack sx={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 2 }}>
							<Button onClick={resetFilters} variant="outlined" size="small">
								{t('lists:button.reset')}
							</Button>
							<Button
								type="submit"
								onClick={() => {
									setIsOpen(false)
								}}
								variant="contained"
								size="small"
							>
								{t('lists:button.search')}
							</Button>
						</Stack>
					</Stack>
					<Divider />
					{children}
				</FilterBarGQL>
			</Drawer>
		</>
	)
}
