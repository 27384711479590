import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { CommentSection } from '../sections/CommentSection'
import { useSendUserIntegrationsMessageMutation } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { searchQuery } from '@features/lists/components/Search'

export default function MassMessagesModal({
	rowCount,
	trackType,
	isOpen,
	setIsOpen,
	userIntegrationsIds,
	integrationCategory
}) {
	const { t } = useTranslation(['userOfferStatus', 'lists'])
	const { filters } = useFilters()
	const count = userIntegrationsIds.length > 0 ? userIntegrationsIds.length : rowCount

	const variables = { userIntegrationsIds, trackType, filters, integrationCategory, searchQuery: searchQuery() }
	const [sendUserIntegrationsMessage] = useSendUserIntegrationsMessageMutation({ variables })

	const sendMessages = async (_, message) => {
		variables.message = message
		await sendUserIntegrationsMessage()
		setIsOpen(false)
	}

	return (
		<Dialog open={isOpen} onClose={() => setIsOpen(false)}>
			<DialogTitle>{t('modal:massMessages.title', { count })}</DialogTitle>
			<Divider />
			<CommentSection
				onValidate={sendMessages}
				isMessage
				userIds={userIntegrationsIds}
				label={t('modal:massMessages.message')}
			/>
		</Dialog>
	)
}
