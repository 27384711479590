import { TrackOfferFilter } from './TrackOfferFilter'
import { AssessorsFilter } from './AssessorsFilter'
import { useTranslation } from 'react-i18next'
import { type ReactElement } from 'react'

export const OfferAndJury = (): ReactElement => {
	const { t } = useTranslation()

	return (
		<>
			<TrackOfferFilter keyName="orgaOffer.id" labelAll={t('lists:filter.offer.allLabel')} />
			<AssessorsFilter keyName="assessors" />
		</>
	)
}
