import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ValidateButton from '../buttons/ValidateButton'
import { useTranslation } from 'react-i18next'
import { DialogSection } from '@shared/components/modal/dialogSection'

export default function BooleanUpdater({ onValidate, value, setvalue, title, field }) {
	const { t } = useTranslation(['lists'])

	const handleSelect = (event) => {
		setvalue(event.target.value)
	}

	return (
		<DialogSection style={{ display: 'flex', flexDirection: 'row' }}>
			<label htmlFor="massUpdate-english">{title}</label>
			<Select id="massUpdate-english" value={value} onChange={handleSelect} className="main-dialog-element">
				<MenuItem value>{t('modal:massUpdate.englishEnabled')}</MenuItem>
				<MenuItem value={false}>{t('modal:massUpdate.englishDisabled')}</MenuItem>
			</Select>
			<ValidateButton field={field} onValidate={onValidate} value={value} />
		</DialogSection>
	)
}
