import { type GetMenuTilesQuery } from '@gqlTypes'
import { Grid, Typography } from '@mui/material'
import { ConditionalWrapper } from '@shared/components/ConditionalWrapper'
import { type ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { PercentageChip } from './PercentageChip'

interface Props {
	pathname: string
	indicator: GetMenuTilesQuery['getMenuTiles'][0]['indicators'][0]
}

export const TileIndicator = ({ pathname, indicator }: Props): ReactElement => {
	const [isHovered, setIsHovered] = useState(false)
	const { title, value, color, percentage, frontFilters } = indicator

	const hasFilters = frontFilters.length > 0
	const isFocused = isHovered && hasFilters

	return (
		<Grid item xs={4}>
			<ConditionalWrapper
				condition={frontFilters.length > 0}
				wrapper={(children) => {
					return (
						<Link
							onMouseOver={() => {
								setIsHovered(true)
							}}
							onMouseLeave={() => {
								setIsHovered(false)
							}}
							to={{ pathname, state: { routeFilters: frontFilters } }}
							style={{ textDecoration: 'none', cursor: 'pointer' }}
						>
							{children}
						</Link>
					)
				}}
			>
				<Typography
					variant="h3"
					sx={{ fontSize: 34, color, fontWeight: 'bold', textDecoration: isFocused ? 'underline' : 'none' }}
				>
					{value}
				</Typography>
				<Typography sx={{ fontsize: 13, color: 'grays.gray2' }}>{title}</Typography>
				<PercentageChip color={color} value={percentage} />
			</ConditionalWrapper>
		</Grid>
	)
}
