import { InfoCardContent } from '@ui/core/InfoCardContent'
import { useTranslation } from 'react-i18next'
import { Fragment, type ReactElement } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { SelfEvaluationMarker } from '../markers/SelfEvaluationMarker'
import { ImageResponseMarker } from '../markers/ImageResponseMarker'
import { EcosystemMarker } from '../markers/EcosystemMarker'
import { FormSlider } from '@ui/core/FormSlider'
import { SliderTitle } from '@ui/core/SliderTitle'
import { useFormContext } from 'react-hook-form'
import { type InterviewSkill } from '@features/details/hooks/useInterviewSkills'
import { InputTextFieldForm } from '../forms/fields/inputTextFieldForm'

interface Props {
	title: string
	skillList: InterviewSkill[]
	isReadOnly: boolean
	onClick: (skillId: string) => void
}

export const InterviewEvaluationCard = ({ skillList, isReadOnly, onClick }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { control, getValues } = useFormContext()
	const validateComment = (skillId: string, value: string): boolean | string => {
		const score = getValues(`skillScores.${skillId}`)
		return score > 0 && score < 3 && value === '' ? t('translation:interviewDetailPage.comment.globalLabel') : true
	}

	if (skillList.length === 0) return null

	return (
		<InfoCardContent>
			<Grid container rowSpacing={6} columnSpacing={12}>
				{skillList.map(
					({
						id,
						mandatory,
						interviewScore,
						feedbackAverage,
						imageResponse,
						imageResponseDetailedLabel,
						selfEvaluationScore,
						selfEvaluatedDetailedLabel,
						label,
						descriptionOrga,
						maturityLevel
					}) => (
						<Fragment key={id}>
							<Grid item md={6} xs={12}>
								<Stack>
									<SliderTitle isRequired={mandatory} label={label}>
										{descriptionOrga}
									</SliderTitle>
									<FormSlider
										subLabels={maturityLevel}
										name={`skillScores.${id}`}
										defaultValue={interviewScore}
										step={1}
										min={0}
										max={5}
										markers={
											<>
												<Box
													onClick={() => {
														onClick(id)
													}}
													sx={{ cursor: 'pointer' }}
												>
													<EcosystemMarker value={feedbackAverage} />
												</Box>
												<ImageResponseMarker
													value={imageResponse}
													imageResponseDetailedLabel={imageResponseDetailedLabel}
												/>
												<SelfEvaluationMarker
													value={selfEvaluationScore}
													selfEvaluatedDetailedLabel={selfEvaluatedDetailedLabel}
												/>
											</>
										}
										isReadOnly={isReadOnly}
										rules={{
											min: { value: mandatory ? 1 : 0, message: t('translation:interviewDetailPage.score.error') }
										}}
									/>
								</Stack>
							</Grid>
							<Grid item md={6} xs={12}>
								<InputTextFieldForm
									rules={{ validate: (value: string) => validateComment(id, value) }}
									name={`skillComments.${id}`}
									control={control}
									minRows={4}
									multiline
									disabled={isReadOnly}
									fullWidth
								/>
							</Grid>
						</Fragment>
					)
				)}
			</Grid>
		</InfoCardContent>
	)
}
