import { type ReactElement, type ReactNode } from 'react'

interface Props {
	children?: ReactNode
}

export const SoftskillsIndicatorsIcon = ({ children }: Props): ReactElement => {
	return (
		<span
			style={{
				width: 30,
				display: 'inline-flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				marginRight: 10,
				fontSize: 20,
				verticalAlign: 'middle',
				paddingBottom: 2
			}}
		>
			{children}
		</span>
	)
}
