import { type ReactElement, type SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '@ui/core/TextField'
import Button from '@mui/material/Button'
import { DialogSection } from '@shared/components/modal/dialogSection'

interface Props {
	onValidate: (userIds: string | string[], comment: string) => void
	userIds: string
	label: string
}

export const CommentSection = ({ onValidate, userIds, label }: Props): ReactElement => {
	const [comment, setComment] = useState('')
	const { t } = useTranslation(['userOfferStatus', 'lists'])

	const validate = (): void => {
		if (userIds != null) onValidate(userIds, comment)
		else onValidate('comment', comment)
	}

	return (
		<DialogSection vertical>
			<TextField
				label={label}
				variant="outlined"
				fullWidth
				multiline
				onChange={(event: { target: { value: SetStateAction<string> } }) => {
					setComment(event.target.value)
				}}
				rows={4}
			/>
			<div style={{ textAlign: 'right', width: '100%' }}>
				<Button disabled={comment.length === 0} onClick={validate}>
					{t('modal:massUpdate.update')}
				</Button>
			</div>
		</DialogSection>
	)
}
