import { Stack, Typography } from '@mui/material'
import { Slider } from '@ui/core/Slider'
import { type ReactElement } from 'react'

interface Props {
	text: string
	score: number
}

export const SharedDetails = ({ text, score }: Props): ReactElement => {
	return (
		<div>
			<Typography>{text}</Typography>
			<Stack sx={{ mt: -1 }} direction="row" spacing={2}>
				<Slider size={3} sx={{ flex: 1, display: 'flex' }} readOnly value={score} color="primary" min={0} max={5} />
				<Typography>{score} / 5</Typography>
			</Stack>
		</div>
	)
}
