import React, { type ReactElement, type ReactNode, useEffect, useState } from 'react'
import MaterialSlider from '@mui/material/Slider'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import SliderLabel from './SliderLabel'
import { valueToPercent } from '../../helper/number.helper'
import { Box, useTheme, type SxProps, type Theme } from '@mui/material'

interface Props {
	sx?: SxProps<Theme>
	min: number
	max: number
	markers?: ReactNode
	step?: number
	delimiter?: number
	startLabel?: string
	endLabel?: string
	hasSubLabel?: boolean
	children?: ReactNode
	defaultValue?: number
	value?: number
	id?: string
	color?: 'secondary' | 'primary'
	className?: string
	readOnly: boolean
	disabled?: boolean
	onChange?: (value: number) => void
	size?: number
	baseScore?: number
	isDarkMode?: boolean
	style?: React.CSSProperties
	trackColor?: string
}

interface StyleProps {
	size?: number
	isNotEvaluated: boolean
	railColor: string
	isDarkMode: boolean
	readOnly: boolean
	trackColor?: string
}

const Slider1 = ({
	sx,
	min = 0,
	max = 100,
	markers,
	step = 1,
	delimiter,
	startLabel = '',
	endLabel = '',
	hasSubLabel = false,
	children,
	defaultValue = 0,
	value,
	id = '',
	color = 'secondary',
	className = '',
	readOnly,
	disabled = false,
	onChange = () => {},
	size,
	baseScore = 1,
	isDarkMode = false,
	style,
	trackColor
}: Props): ReactElement => {
	const { t } = useTranslation()
	const theme = useTheme()
	const isControlled = value != null
	const givenValue = value ?? defaultValue

	const [actualValue, setActualValue] = useState(0)
	const isNotEvaluated = actualValue === 0
	const subLabelValue = Math.round(actualValue / baseScore).toString() ?? '0'

	const [isOpen, setIsOpen] = useState(false)
	const railColor = isDarkMode ? theme.palette.grays.gray1 : theme.palette.grays.gray2

	const hasUpperLabel = startLabel !== '' || endLabel !== ''
	const isOpenLabelKey = isOpen ? 'true' : 'false'

	useEffect(() => {
		if (givenValue != null) setActualValue(givenValue)
	}, [givenValue])

	const styleProps: StyleProps = {
		size,
		isNotEvaluated,
		railColor,
		isDarkMode,
		readOnly,
		trackColor
	}
	const { classes: sliderClasses } = useSliderStyle(styleProps)

	const handleOnChange = (value: number): void => {
		if (!isControlled) setActualValue(value)
		onChange(value)
	}

	return (
		<Box sx={sx}>
			<Box sx={sx} style={{ display: 'flex', ...style }}>
				<span
					style={{ width: '100%', position: 'relative', marginTop: markers != null && hasUpperLabel ? 25 : 0 }}
					className={className}
				>
					{delimiter != null && (
						<span
							style={{
								left: valueToPercent(delimiter, min, max) + '%',
								position: 'absolute',
								bottom: -17,
								marginLeft: 4,
								height: 30,
								transform: 'translateY(-50%)',
								borderLeft: 'solid grey 1px'
							}}
						/>
					)}
					{hasUpperLabel && (
						<SliderLabel id={id} endLabel={endLabel} min={min} startLabel={startLabel} value={actualValue} />
					)}
					{markers}
					<div style={{ width: '100%' }}>
						<MaterialSlider
							marks={!readOnly}
							value={actualValue}
							onChange={(_e, _, v) => {
								if (v === actualValue) return
								handleOnChange(v)
							}}
							disabled={readOnly || disabled}
							classes={sliderClasses}
							step={step}
							max={max}
							min={min}
							color={color}
						/>
					</div>
				</span>
			</Box>
			{hasSubLabel && (
				<Typography style={{ marginLeft: readOnly ? 0 : 34 }}>
					{t(`sliders:subLabel.${subLabelValue ?? '0'}`)}
				</Typography>
			)}
			{children != null ? (
				<div
					onClick={() => {
						setIsOpen(!isOpen)
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row-reverse'
						}}
					>
						<Typography
							style={{ cursor: 'pointer', color: theme.palette.grays.gray2, display: 'inline-block', fontSize: 13 }}
						>
							{t(`sliders:open.${isOpenLabelKey}`)}
						</Typography>
					</div>
					{isOpen && children}
				</div>
			) : null}
		</Box>
	)
}

export const Slider = React.memo(Slider1)

const useSliderStyle = makeStyles<StyleProps>()(
	(theme, { size, isNotEvaluated, railColor, isDarkMode, readOnly, trackColor }) => ({
		root: {
			height: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		rail: {
			color: railColor,
			height: size ?? 6,
			opacity: isDarkMode ? 1 : 0.38
		},
		track: {
			height: size ?? 5,
			borderRadius: 6,
			color: trackColor ?? theme.palette.secondary.main
		},
		thumb: {
			marginRight: 0,
			marginLeft: 5,
			marginTop: 0,
			width: 22,
			height: 22,
			backgroundColor: isNotEvaluated ? railColor : theme.palette.secondary.main,

			'&:not(.MuiSlider-active)': {
				display: 'none',
				pointerEvents: 'none'
			},

			'&[data-index="0"]': {
				display: 'flex'
			}
		},
		disabled: {
			'& .MuiSlider-thumb': {
				display: 'none !important'
			}
		},
		mark: {
			opacity: readOnly ? 0 : 1,
			backgroundColor: '#ECE4E4',
			width: 12,
			height: 12,
			borderRadius: '100%'
		},
		markActive: {
			backgroundColor: theme.palette.secondary.main
		}
	})
)
