import { type GetAssessorsQuery, useGetAssessorsQuery } from '@gqlTypes'
import { Popper, Autocomplete, type PopperProps } from '@mui/material'
import TextField from '@ui/core/TextField'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Assessor = GetAssessorsQuery['getAssessors'][0]

export default function AssessorSelect(): JSX.Element {
	const { data } = useGetAssessorsQuery()
	const assessors = [...(data?.getAssessors ?? [])]

	const { t } = useTranslation()
	const getSelectedAssessors = (assessorIds: Record<string, string>): Assessor[] => {
		return Object.keys(assessorIds)
			.map((assessorId) => assessors.find((assessor: Assessor) => assessor.id === assessorId))
			.filter((assessor) => assessor != null)
	}

	const {
		field: { onChange, value }
	} = useController({ name: 'assessors' })

	const selectedAssessor = getSelectedAssessors(value as Record<string, string>)

	assessors.sort((a, b) => a.reverseFullName.localeCompare(b.reverseFullName))

	const onMultiChange = (newValue: Assessor[]): void => {
		const payload: Record<string, string> = {}
		newValue.map((assessor) => (payload[assessor.id] = assessor.id))
		onChange(payload)
	}

	const AutocompletePopper = (props: PopperProps): JSX.Element => {
		return <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
	}

	return (
		<Autocomplete
			multiple
			limitTags={-1}
			options={assessors}
			PopperComponent={AutocompletePopper}
			getOptionLabel={(option) => option.reverseFullName}
			onChange={(_: unknown, newValue: Assessor[]) => {
				onMultiChange(newValue)
			}}
			value={selectedAssessor}
			renderInput={(params) => <TextField {...params} label={t('interviews:columns.assessor')} variant="standard" />}
			style={{ width: 200 }}
			disableClearable
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.id}>
						{option.reverseFullName}
					</li>
				)
			}}
		/>
	)
}
