import { useEffect, type ReactElement } from 'react'
import {
	type GetUserInterviewListNodesFragment,
	type UpdateInterviewInput,
	useUpdateInterviewMutation
} from '@gqlTypes'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { ErrorHandler } from '#helpers/error.helper'
import { UserAvatar } from '@shared/components/UserAvatar'
import { AssessorField } from '../create/AssessorField'
import { DateField } from '../create/DateField'
import CheckBoxField from '@shared/components/forms/fields/CheckBoxField'
import { LabelField } from '../create/LabelField'
import { DurationField } from '../create/DurationField'
import { StartTimeField } from '../create/StartTimeField'
import { InterviewCustomFields } from '../create/InterviewCustomFields'
import { LoadingButton } from '@mui/lab'
import { FormProvider, useForm } from 'react-hook-form'
import { useGqlSnackBar } from '@shared/gql/hooks/useGqlSnackBar'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'

interface Props {
	interview: GetUserInterviewListNodesFragment
	handleClose: () => void
	isOpen: boolean
	isInterviewLabelAvailable: boolean
}

interface FormInputs {
	juryId: string
	missedInterview: boolean
	label: string
	startTime: string
	durationId: string
	interviewDate: string
	customFields: Record<string, string>
}

export const InterviewCardEditDialog = ({
	interview,
	handleClose,
	isOpen,
	isInterviewLabelAvailable
}: Props): ReactElement => {
	const defaultValues: FormInputs = {
		juryId: interview?.juryId ?? '',
		missedInterview: interview.missedInterview ?? false,
		label: interview.label ?? '',
		startTime: interview.hasStartTime ? interview.interviewDate : null,
		interviewDate: interview.interviewDate,
		durationId: interview.durationId,
		customFields: interview.customFields
	}

	const formMethods = useForm<FormInputs>({ mode: 'onChange', defaultValues })
	const { handleSubmit, reset, errors } = formMethods

	useEffect(() => {
		reset(defaultValues)
	}, [interview])
	const [updateInterviewMutation] = useUpdateInterviewMutation()
	const { onCompleted, onError } = useGqlSnackBar('interviews:update')
	const { t } = useTranslation()

	const updateInterview = (interviewInput: UpdateInterviewInput): void => {
		;(async () => {
			const variables = {
				interviewId: interview.id ?? '',
				interviewInput
			}
			await updateInterviewMutation({ variables, onCompleted, onError })
		})().catch(ErrorHandler)
		handleClose()
	}

	return (
		<Dialog onClose={handleClose} open={isOpen}>
			<FormProvider {...formMethods}>
				<DialogCloseButton onClick={handleClose} />
				<DialogTitle color="primary" sx={{ mb: 3, fontWeight: 'bold' }}>
					{t('interviews:update.title')}
				</DialogTitle>
				<DialogContent>
					<Stack direction="row" gap={2} sx={{ mb: 5 }} alignItems="center">
						<UserAvatar user={interview.userRef} />
						<Box>
							<Typography sx={{ fontWeight: 'bold' }}>{interview.assessedFullName}</Typography>
							<Typography>{interview.track.label}</Typography>
						</Box>
					</Stack>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6}>
							<AssessorField />
						</Grid>
						{interview.hasTargetDate ? (
							<Grid item xs={12} sm={6}>
								<TextField
									label={t('interviews:columns.targetDate')}
									variant="standard"
									value={DateTime.fromISO(interview.targetDate ?? '').toFormat('dd/MM/yyyy')}
									disabled
								/>
							</Grid>
						) : null}
						<Grid item xs={12} sm={6}>
							<FormControlLabel
								control={<CheckBoxField name="missedInterview" />}
								label={t('interviews:update.missedInterview')}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<DateField name="interviewDate" label={t('interviews:columns.date')} />
						</Grid>
						{isInterviewLabelAvailable ? (
							<Grid item xs={12} sm={6}>
								{isInterviewLabelAvailable ? <LabelField /> : null}
							</Grid>
						) : null}
						<InterviewCustomFields errors={errors} hasGrid />
					</Grid>
				</DialogContent>
				<Divider />
				<DialogContent>
					<Typography color="primary" sx={{ fontWeight: 'bold', mb: 3 }}>
						{t('drawer:interview.invitation')}
					</Typography>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6}>
							<StartTimeField />
						</Grid>
						<Grid item xs={12} sm={6}>
							<DurationField />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t('employees:update.cancel')}</Button>
					<LoadingButton onClick={handleSubmit(updateInterview)} color="secondary" variant="contained">
						{t('employees:update.edit')}
					</LoadingButton>
				</DialogActions>
			</FormProvider>
		</Dialog>
	)
}
