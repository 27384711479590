import Divider from '@mui/material/Divider'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import Typography from '@mui/material/Typography'
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { useTranslation } from 'react-i18next'
import { type ReactElement } from 'react'
import { SoftskillsIndicatorsIcon } from './SoftskillsIndicatorsIcon'
import { useTheme } from '@mui/material'

interface Props {
	isSoftskillReturnShared: boolean
	isSoftskillTestShared: boolean
}

export const SoftskillsIndicatorsLegend = ({ isSoftskillReturnShared, isSoftskillTestShared }: Props): ReactElement => {
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<>
			<Divider />
			<InfoCardContent
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					gap: 10
				}}
			>
				<Typography>
					<SoftskillsIndicatorsIcon />
					<div
						style={{
							height: 20,
							borderLeft: 'solid currentcolor 1px',
							color: theme.palette.secondary.main
						}}
					></div>
					{t('translation:candidates.profile.requirement')}
				</Typography>
				<Typography>
					<SoftskillsIndicatorsIcon />
					<div
						style={{
							width: '100%',
							height: 6,
							borderRadius: 6,
							background: `linear-gradient(to right, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark})`
						}}
					></div>
					{t('translation:candidates.profile.interviewEvaluation')}
				</Typography>

				<Typography>
					<SoftskillsIndicatorsIcon>
						<SupervisedUserCircleOutlinedIcon fontSize="inherit" />
					</SoftskillsIndicatorsIcon>
					{t('translation:candidates.profile.ecosystemReturn')}
					{!isSoftskillReturnShared && <> {t('translation:candidates.profile.softSkills.unshared')}</>}
				</Typography>

				<Typography>
					<SoftskillsIndicatorsIcon>
						<AccountCircleOutlinedIcon fontSize="inherit" />
					</SoftskillsIndicatorsIcon>
					{t('translation:candidates.profile.selfEvaluation')}
					{!isSoftskillTestShared && <> {t('translation:candidates.profile.softSkills.unshared')}</>}
				</Typography>
			</InfoCardContent>
		</>
	)
}
