import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ArchivedUpdateSection from '../sections/booleanUpdaters/ArchivedUpdateSection'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { setSelectedRows } from '@shared/store/actions/selectedRows.actions'
import { IntegrationCategory, TrackType } from '@gqlTypes'
import ManagerUpdateSection from '../sections/selectorUpdaters/ManagerUpdateSection'
import StatusUpdate from '../sections/selectorUpdaters/StatusUpdateDialog'
import EnglishUpdateSection from '../sections/booleanUpdaters/EnglishUpdateSection'
import AssessorsUpdateSection from '../sections/AssessorsUpdateSection'
import { CommentSection } from '../sections/CommentSection'
import { type ApolloQueryResult, type FetchResult } from '@apollo/client'
import { useFilters } from '@shared/hooks/useFilters'
import { type ReactElement } from 'react'
import { StudentMassUpdateModal } from '@domains/students/list/StudentMassUpdateModal'
import { searchQuery } from '@features/lists/components/Search'

interface Props {
	isOpen: boolean
	isLoading: boolean
	setOpen: (isOpen: boolean) => void
	query: (variables: Record<string, unknown>) => Promise<FetchResult<unknown>>
	ids: string[]
	refetch: () => Promise<ApolloQueryResult<unknown>>
	count: number
	trackType?: TrackType
	integrationCategory: IntegrationCategory
}

export const MassUpdateModalGql = ({
	isOpen,
	isLoading,
	setOpen,
	query,
	ids,
	refetch,
	count,
	trackType,
	integrationCategory
}: Props): ReactElement | null => {
	const { t } = useTranslation(['lists'])
	const dispatch = useDispatch()
	const rowCount = ids.length > 0 ? ids.length : count
	const { filters } = useFilters()
	const validFilters = filters.filter((filter) => filter.value !== 'empty')
	const hasTrackType = trackType != null

	const sendUpdate = async (field: string, newValue: string | boolean): Promise<void> => {
		dispatch(setSelectedRows([]))
		const variables = {
			ids,
			payload: { [field]: newValue },
			filters: validFilters,
			trackType,
			integrationCategory,
			searchQuery: searchQuery()
		}
		await query({ variables })
		await refetch()
		setOpen(false)
	}

	if (!isOpen) return null

	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				setOpen(false)
			}}
			maxWidth="md"
		>
			<DialogTitle>{t('modal:massUpdate.title', { count: rowCount })}</DialogTitle>
			<Divider />
			{isLoading ? (
				<DialogSection style={{ height: 415.5 }}>
					<LocalLoader />
				</DialogSection>
			) : (
				<div>
					{hasTrackType ? (
						<>
							{trackType === TrackType.Position ? (
								integrationCategory === IntegrationCategory.School ? (
									<StudentMassUpdateModal sendUpdate={sendUpdate} />
								) : (
									<>
										<ArchivedUpdateSection onValidate={sendUpdate} />
										<Divider />
										<ManagerUpdateSection onValidate={sendUpdate} />
										<Divider />
									</>
								)
							) : (
								<>
									<StatusUpdate onValidate={sendUpdate} />
									<Divider />
									<EnglishUpdateSection onValidate={sendUpdate} />
									<Divider />
									<AssessorsUpdateSection onValidate={sendUpdate} />
									<Divider />
									<ArchivedUpdateSection onValidate={sendUpdate} />
									<Divider />
								</>
							)}
							<CommentSection onValidate={sendUpdate} label={t('modal:massUpdate.comment')} userIds={undefined} />
						</>
					) : (
						<ArchivedUpdateSection onValidate={sendUpdate} />
					)}
				</div>
			)}
		</Dialog>
	)
}
