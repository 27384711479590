import { useParams } from 'react-router-dom'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useTranslation } from 'react-i18next'
import { StaticSpecificInfoCard } from './StaticSpecificInfoCard'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import useUserBContext from '@shared/hooks/useUserBContext'
import { useGetInterviewDetailsQuery } from '@gqlTypes'
import { MotivationForm } from '@domains/motivations/MotivationForm'
import { PresentationLinkCard } from './PresentationLinkCard'
import { Box, Container, Stack } from '@mui/material'
import { useRole } from '@shared/hooks/useRole'
import { InterviewSignatures } from '../signature/InterviewSignatures'
import { Masonry } from '@mui/lab'
import { PersonalityProfileCard } from '@shared/components/cards/PersonalityProfileCard'
import { CommunicationKeyCard } from '@shared/components/cards/CommunicationKeyCard'
import { InterviewForm } from '@features/details/components/interviewTab/InterviewForm'
import { InterviewAttachments } from '../attachment/InterviewAttachments'
import { InterviewSection } from './InterviewSection'
import { InterviewSurvey } from './InterviewSurvey'
import { KeyInformationsCard } from './KeyInformationsCard'
import { InterviewPdf } from './InterviewPdf'
import { useRef } from 'react'

export default function InterviewDetailPage() {
	const { interviewId } = useParams()
	const { t } = useTranslation()
	const userBContext = useUserBContext()
	const { isExternal } = useRole()

	const { loading: isLoading, data } = useGetInterviewDetailsQuery({
		variables: { interviewId },
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first'
	})

	const name = data?.getInterview?.userIntegration?.user?.fullName
	useDocumentTitle(t('translation:candidates.interview.title', { name }))
	const tabBarContainer = useRef(null)

	if (isLoading || data === undefined) return <LocalLoader />

	const connectedUserId = data?.me?.id
	const interview = data.getInterview
	const userIntegration = interview.userIntegration
	const track = interview.track
	const organization = userIntegration.organization
	const user = userIntegration.user
	const { personalityTest, profilingId } = user
	const { avatar, profile, highestDimensions } = personalityTest.length > 0 ? personalityTest[0] : {}
	const videoConferenceLink =
		interview.videoConferenceLink !== '' ? interview.videoConferenceLink : userBContext.visioLink
	const isManager = userBContext.id !== interview.userId

	return (
		<Container maxWidth="xl" sx={{ marginBottom: 15 }}>
			<Box sx={{ position: 'sticky', top: 0, zIndex: 1, marginBottom: 2 }} ref={tabBarContainer} />
			<Stack spacing={3} mb={6}>
				<InterviewSection title={t('interview:informations.section')}>
					<Masonry columns={2} spacing={3}>
						<StaticSpecificInfoCard
							isEnglish={userIntegration.english}
							comment={userIntegration.comment}
							visioLink={videoConferenceLink}
						/>
						<KeyInformationsCard
							category={track.category ?? ''}
							registerDateLabel={userIntegration.registerDateLabel}
							publicTitle={userIntegration.user?.publicTitle ?? ''}
						/>
						<PersonalityProfileCard avatar={avatar} profile={profile} highestDimensions={highestDimensions} />
						{organization.isInterviewAttachmentActive ? (
							<InterviewAttachments {...{ interview, connectedUserId }} />
						) : null}
						<CommunicationKeyCard profilingId={profilingId} profileId={profile?.id} />
						<PresentationLinkCard link={user.link} />
					</Masonry>
				</InterviewSection>
				{!isExternal ? <MotivationForm interview={interview} /> : null}
				<InterviewSurvey isShowMoreExpandedByDefault interview={interview} />
				<InterviewForm
					interview={interview}
					track={track}
					userIntegration={userIntegration}
					tabBarContainer={tabBarContainer}
				/>
				<InterviewSignatures
					isEditable={!interview.isArchived}
					isVisible={interview.stepsState.interviewSignature !== undefined && interview.stepsState.interview}
					interviewId={interviewId}
					signatures={interview.signatures}
					assessorFullName={interview.assessorFullName}
					assessedFullName={interview.assessedFullName}
					assessedId={interview.userId}
					assessorId={interview.juryId}
				/>
				<InterviewPdf
					interviewId={interviewId}
					pdfUrl={interview.pdfUrl}
					hasGenerateRights={!interview.isDraft && isManager}
				/>
			</Stack>
		</Container>
	)
}
